
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
//@ts-ignore
import { isValidFormat } from "@/services/rules-upload-service";

@Component
export default class ImageSelector extends Vue {
	@Prop() value!: string | null;

	dragging: boolean = false;
	dropping: boolean = false;

	imagePreview: string | null = null;

	get acceptedFileTypes(): string {
		return ".jpg, .jpeg, .png";
	}

	getAvatarSize() {
		//@ts-ignore
		return this.$vuetify.breakpoint.lgAndUp ? 200 : 100;
	}

	created() {
		this.imagePreview = this.value;
	}

	@Watch("value")
	onValueChange(newValue: string | null, oldValue: string | null) {
		this.imagePreview = newValue;
	}

	handleFileChange(event: Event): void {
		const fileInput = event.target as HTMLInputElement;
		const file = fileInput.files![0];
		this.handleFile(file);
	}

	handleDrop(event: DragEvent): void {
		event.preventDefault();
		const file = event.dataTransfer?.files[0];
		if (file) {
			this.handleFile(file);
		}

		this.dragging = false;
		this.dropping = false;
	}

	allowDrop(event: DragEvent): void {
		event.preventDefault();
		this.dragging = true;
	}

	handleDragEnter(event: DragEvent): void {
		event.preventDefault();
		this.dropping = true;
	}

	handleDragLeave(event: DragEvent): void {
		event.preventDefault();
		this.dropping = false;
	}
	triggerFileInput(): void {
		const fileInput = this.$refs.fileInput as HTMLInputElement;
		fileInput.click();
	}

	private async loadImage(src: string, image: HTMLImageElement) {
		return new Promise((resolve, reject) => {
			image.onload = () => {
				resolve({});
			};
			image.onerror = () => {
				reject();
			};
			image.src = src;
		});
	}

	private async handleFile(file: File): Promise<void> {
		if (!isValidFormat(file)) {
			const message = this.$t("file.validations.format", {
				formats: this.acceptedFileTypes,
			});
			alert(message);
			return;
		}

		const image = new Image();
		const reader = new FileReader();

		reader.onload = async () => {
			try {
				const src = reader.result as string;
				await this.loadImage(src, image);
				let self = this;
				self.imagePreview = src;

				const avatarData = {
					upload_file: file,
					avatar_file: self.imagePreview,
				};
				self.$emit("update", avatarData);
			} catch (error) {
				const message = this.$t("file.validations.error");
				alert(message);
			}
		};

		reader.readAsDataURL(file);
	}

	cancelImage(): void {
		this.imagePreview = null;
		this.$emit("update", {
			upload_file: null,
			avatar_file: null,
		});
		const fileInput = this.$refs.fileInput as HTMLInputElement;
		fileInput.value = ""; // Reset the input file value to allow selecting the same image again
	}

	changeImage(): void {
		const fileInput = this.$refs.fileInput as HTMLInputElement;
		fileInput.value = ""; // Reset the input file value to allow selecting a new image
		this.triggerFileInput(); // Open the file dialog to select a new image
	}
}
